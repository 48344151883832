import React, {useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";

import Layout from "../components/layout";
import { PaginationFooter } from "../components/sections";
import { DisplayHeader, WineryList } from "../components/composites";
import { getFilteredWineries, setExperienceArray, setCountryOptions } from "../utils";

import './tag.scss'

const TagTemplate = ({data, location}) => {
    const {
        directus: {
            tags: featuredTag,
            wineries,
            countries
        }
    } = data;

    const { search } = location;

    const params = new URLSearchParams(search);
    const tag = featuredTag[0] ?? ''
    const listRef = useRef(null);
    const tagName = tag?.name ?? '';
    const description = tag?.description ?? null;
    const backgroundColor = tag?.color ?? null;
    const image = tag?.featured_image ?? null;
    const wineryLength = wineries.length;
    const pageNumber = Number(params.get("page") ?? 1);
    const startWineryDisplay = (pageNumber - 1) * 18;
    const endWineryDisplay = pageNumber * 18;

    const primaryWinerySlug = tag?.primary_winery?.slug ?? wineries[0].slug;
    const secondaryWinerySlug = wineryLength > 1 ? (tag?.secondary_winery?.slug ?? wineries[1].slug) : null;

    const primaryWinery = wineries.find(({slug}) => slug === primaryWinerySlug)
    const secondaryWinery = wineries.find(({slug}) => slug === secondaryWinerySlug)

    const countryOptions = useMemo(() => setCountryOptions(countries), [countries])
    const filterPreppedArray = useMemo(() => setExperienceArray(wineries), [wineries])
    const [filteredWineries, setFilteredWineries] = useState(wineries.filter( ({slug}) => slug !== primaryWinerySlug || slug !== secondaryWinerySlug));
    const [wineriesToDisplay, setWineriesToDisplay] = useState(filteredWineries.slice(startWineryDisplay, endWineryDisplay));


    useLayoutEffect( () => {
        const filteredResults = getFilteredWineries(location, filterPreppedArray)
        setFilteredWineries(filteredResults)
        setWineriesToDisplay(filteredResults.slice(startWineryDisplay, endWineryDisplay))
    }, [filterPreppedArray, location, search])

    return(
        <Layout className='tag-template'
                currentPage='tag'
                title={tagName}
                location={location}
                description={description}
                image={image?.filename_disk ?? null}
        >
            <DisplayHeader
                title={tagName}
                backgroundColor={backgroundColor}
                wineryCount={filteredWineries.length}
                image={image}
                location={location}
                hiddenFilters={['Grape']}
                countryOptions={countryOptions}
                screenReadText='Find wineries that offer'
            >
                <div ref={listRef}/>
                <WineryList wineries={wineriesToDisplay} primaryFeatureWinery={primaryWinery} secondaryFeatureWinery={secondaryWinery} search={search} />
            </DisplayHeader>

            <PaginationFooter setDataToDisplay={setWineriesToDisplay} data={filteredWineries} scrollToRef={listRef} pageNumber={pageNumber} location={location}/>
        </Layout>
    )
}

TagTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

TagTemplate.defaultProps = {

}

export const TagTemplateQuery = graphql`
  query($slug: String!) {
    directus {
      countries(
        filter: {wineries: {id: {_nnull: true}}}
      ) {
        name
        slug
      }
      tags(
        filter: {slug: {_eq: $slug}}
      ) {
        slug
        name
        color
        description
        featured_image {
            filename_disk
            imageFile {
           childImageSharp {
             gatsbyImageData
            }
           }
         }
         primary_winery {
            slug
         }
         secondary_winery {
           slug
         }
      }
      wineries(
        limit: -1
        sort: ["-vind_score"]
        filter: {status: {_eq: "published"}, tags: {tags_id: {slug: {_eq: $slug}}}}
      ) {
         additional_images {
         directus_files_id {
              filename_disk
              }
         }
         slug
         featured_image {
            filename_disk
         }
         google_total_reviews
         google_rating
         name
         country {
            name
            slug
         }
         region
         summary
         accommodation
         cellar_door
         events
         restaurant
         tours
         tastings
         tags {
            tags_id {
              slug
            }
          }  
       }
    }
  }
`

export default TagTemplate